import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import  {encryptTransform}  from 'redux-persist-transform-encrypt';
import userSlice from './Slices/User';
import eventSlice from "./Slices/Event"
import goalSlice from './Slices/Goal';
import pageSlice from './Slices/Pages'

const reducers = combineReducers({
   loginInfo: userSlice,
   eventInfo: eventSlice,
   goalInfo: goalSlice,
   pageInfo: pageSlice

});

const persistConfig = {
	key: 'root',
	storage,
	transforms: [
		encryptTransform({
			secretKey: 'my-super-secret-keyForMeta@Prop@$S',
			onError: function (error) {
				console.warn(error);
			}
		})
	]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        })
});

export default store;
