export const devConfiguration = {
  getLogin: "/admin/login",
  listAllEvent: "/admin/event/listAllEvent",
  listAllUserRegisteredEvent: "/admin/event/getUserRegisteredEventList",
  getUserRegisteredEventById: "/admin/event/getUserRegisteredEventById",
  deleteEvent: "/admin/event/deleteEvent",
  addEvent: "/admin/event/addEvent",
  viewEvent: "/admin/event/eventDetail",
  editEvent: "/admin/event/editEvent",
  addVendor: "/admin/vendor/addVendor",
  getVendor: "/admin/vendor/listVendor",
  getAllVendor: "/admin/vendor/listAllVendor",
  deleteVendor: "/admmin/vendor/deleteVendor",
  updateStatusVendor: "/admin/vendor/updateStatusVendor",
  getCommunities: "/admin/getAllCommunityChallenges",
  getGoal: "/admin/goal/listGoal",
  goalDetails: "/admin/goalsDetails",
  addGoal: "/admin/goal/addGoal",
  deleteGoal: "/admin/goal/deleteGoal",
  editGoal: "/admin/goal/goalEditAdmin",
  getAllUsers: "/admin/getAllUsers",
  userDetails: "/admin/getUserDetail",
  changeBlockStatus: "/admin/changeBlockStatus",
  getCommunityChallengeDetails: "/admin/getCommunityChallengeDetail",
  addCommunityChallenge: "/admin/addCommunityChallenge",
  deleteCommunityChallenge: "/admin/deleteCommunitiesChallenge",
  getNutrition: "/admin/nutrition/listNutritionAdmin",
  nutritionDetails: "/admin/nutrition/nutritionDetails",
  deleteNutrition: "/admin/nutrition/deleteNutrition",
  editCommunity: "/admin/editCommunityChallenge",
  addNutrition: "/admin/nutrition/addNutrition",
  dashboardDetails: "/admin/getDashboardDetails",
  editNutrition: "/admin/nutrition/editNutrition",
  listMentalHealth: "/admin/mentalHealth/listMentalHealthAdmin",
  addMentalhealth: "/admin/mentalHealth/addMentalHealth",
  deleteMentalHealth: "/admin/mentalHealth/deleteMentalHealth",
  editMentalHealth: "/admin/mentalHealth/editMentalHealth",
  mentalHealthDetails: "/admin/mentalHealth/mentalHealthDetails",
  getAboutUs: "/admin/getAboutUs",
  getPrivacyPolicy: "/admin/getPrivacyPolicy",
  getTerms: "/admin/getTermsAndCondition",
  addAboutUs: "/admin/addAboutUs",
  addTerms: "/admin/addTermsAndCondition",
  addPrivacy: "/admin/addPrivacyPolicy",
  getFeedbacks: "/admin/feedbacks",
  communityReports: "/admin/communityReports",
  deleteUser: "/admin/deleteUser",
  nutritionShare: "/admin/share",
  getTips: "/admin/listTips",
  addTips: "/admin/addTips",
  viewTip: "/admin/detailOfTips",
  editTip: "/admin/editTips",
  deleteTips: "/admin/deleteTips",
  addUserProfileTips: "/admin/addUserProfileTips",
  listuserProfileTips: "/admin/listUserProfileTips",
  editUserProfileTips: "/admin/editUserProfileTips",
  deleteUserProfileTips: "/admin/deleteUserProfileTips",
  detailsOfUserProfileTips: "/admin/detailOfUserProfileTips",
  listPartnerImages: "/admin/partnerImage/listAllPartnerImage",
  addPartnerImage: "/admin/partnerImage/addPartnerImage",
  deletePartnerImage: "/admin/partnerImage/deletePartnerImage",
  getVideos: "/admin/video/getAllVideo",
  addVideo: "/admin/video/addVideo",
  deleteVideo: "/admin/video/deleteVideo",
  videoDetails: "/admin/video/videoDetail",
  editVideo: "/admin/video/videoEdit",
  userReward: "/admin/userReward",
  rewardPointsConfig: "/admin/rewardPointsConfig",
  articles: "/admin/articles",
  articlesById: "/admin/articlesById",
  addArticles: "/admin/articles",
  usersPointsInExcel: "/admin/usersPointsInExcel",
  userRewardDownload: "/admin/userRewardDownload",
  deleteArticles: "/admin/articles",
  editArticles: "/admin/articles",
  allAdmin: "/admin/getAllAdmin",
  signUp: "/admin/signup",
  updateRole: "/admin/updateRole",
  getRolePage: "/admin/getRolePage",
  duplicateEvent: "/admin/event/duplicateEvent",
  duplicateGoal: "/admin/goal/duplicateGoal",
  getFaq: "/admin/faq",
  addFaq: "/admin/faq",
  eventCSV: "/admin/listInterestEvent",
  communityCSV: "/admin/userInterestedChallenegeData",
  widgetRewardPointsConfig: "/admin/widgetRewardPointsConfig",
  unBlockUser: "/admin/unBlockUser",
  getGoalPoints: "/admin/goalPointsConfig",
  extraStepsCoveredPointsConfig: "/admin/extraStepsCoveredPointsConfig",
  reportHistory: "/admin/adminReportHistory",
  postreport:'/admin/report',
  getreport:'/admin/report',
  badgesList: "/admin/badges",
  widgets: "/admin/widgets",
  uploadWidgetImage: "/admin/uploadImage",
  featuredCommunity: "/admin/featuredCommunity/",
  getOrganisation:"/admin/organization",
  accepetRejectOrganisation:"/admin/organization/approveRejectOrganization",
  organisationAccountInfo:"/admin/organization/orgAccountInfoList",
  viewAccountInfo:"/admin/organization/organizationId/",
  updateOrganisation:"/admin/organization",
  deleteOrganisation:"/admin/organization/deleteOrganization",
  employeeList:"/admin/organization/orgEmployeeAssociatedList",
  organisationEmployees:"/admin/organization/employees/",
  employeeDetails:"/admin/organization/orgEmployeeDetail",
  deleteOrganisationEmployee:"admin/organization/deleteOrganizationEmployee",
  organisationDashboard:"/admin/organization/dashboard",
  organisationChallenge:"/admin/organization/challenges/",
  deleteChallenge:"admin/organization/deleteOrganizationChallenge",
  getOrgTerms:"/admin/organization/getTermsAndCondition",
  updateOrgTerms:"admin/organization/addUpdateTermsAndCondition",
  getOrgPrivacyPolicy:"admin/organization/getPrivacyPolicy",
  updateOrgPrivacy:"admin/organization/addUpdatePrivacyPolicy",
  updateTermsOfService:"admin/organization/addUpdateTermsAndService",
  getOrgTermsOfService:"admin/organization/getTermsAndService",
  unverifyUaePass: "/admin/uaeUnverified",
};
export const devAbiConfig = {};
