import { createSlice } from "@reduxjs/toolkit";

export const goalSlice = createSlice({
  name: "goalInformation",
  initialState: {
    goalInformation: {},
  },
  reducers: {
    setGoalData: (state, action) => {
      state.goalInformation = action.payload;
    },
  },
});

export const { setGoalData } = goalSlice.actions;

export default goalSlice.reducer;
