import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router-dom";
import image from "../../../src/assets/images/logo.svg";
import profile from "../../../src/assets/images/demo.jpg";
import { useSelector } from "react-redux";
import { PopUp } from "../../utils/utilities";
import { handleApiCall } from "../../apis";
import { endpoints } from "../../utils/endpoints";
import {useDispatch } from "react-redux";
import { setPageData } from "../../redux/Slices/Pages";


const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logout, showLogout] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [logoutModal, setLogoutModal] = useState({ value: false });
  const [adminList, setAdminList] = useState();


  const loginSuccess = useSelector(
    (state) => state.loginInfo.userInformation.data
  );

  const logOutClickHandler = (event) => {
    event.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (localStorage.getItem("sessionToken")) {
     return;
    } else {
      PopUp("Session Expired.");
      navigate("/");  
    }
  }, []);

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("sessionToken")) {
    handleAdminList();
    }
  }, []);

  const handleAdminList = async () => {

    try {
      const response = await handleApiCall(
        "get",
        `${endpoints.getRolePage}`
      );
      if (response.data.status == true) {
        setAdminList(response?.data?.data);
        dispatch(setPageData(response?.data?.data));
      
      }
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo">
            <img src={image} alt="logo" height="55px" />
          </a>
          <a className="navbar-brand brand-logo-mini">
            <img src={image} alt="logo" />
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <ul className="navbar-nav navbar-nav-right">
            <li
              className={`nav-item nav-profile dropdown${
                logout ? " show" : ""
              }`}
              style={{ cursor: "pointer" }}
              onClick={logOutClickHandler}
            >
              <a
                className="nav-link align-items-center"
                onClick={() => showLogout(!logout)}
              >
                <div className="nav-profile-img">
                  <img src={profile} alt="image" />
                </div>
                <div className="nav-profile-text">
                  <p className="mb-0">{loginSuccess?.name}</p>
                </div>
              </a>
              {logout && (
                <div className={`dropdown-menu${logout ? " show" : ""}`}>
                  <a
                    class="dropdown-item"
                    onClick={() => setLogoutModal({ value: true })}
                  >
                    <i class="mdi mdi-logout mr-2"></i> Logout
                  </a>
                </div>
              )}
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu color-lightblue" />
          </button>
        </div>
        <Modal
          show={logoutModal.value}
          onClose={() => setLogoutModal({ value: false })}
          style={{ zIndex: "1000" }}
        >
          <div className="modal-dialog modal-md modal-center" role="document">
            <div className="modal-content">
              <div className="modal-body text-center my-4 py-4">
                <h4 className="text-dark">
                  Do you want to
                  <br />
                  Logout?
                </h4>
                <div className="col-sm-12 mx-auto d-flex align-items-center mt-4 row">
                  <div className="col-6 px-2">
                    <a
                      type="button"
                      className="btn btn-primary	btn-lg btn-block font-16"
                      onClick={logOut}
                    >
                      Yes
                    </a>
                  </div>
                  <div className="col-6 px-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-lg btn-block font-16"
                      data-dismiss="modal"
                      onClick={() => setLogoutModal({ value: false })}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </nav>
    </>
  );
};

export default Header;
