import React, { useEffect } from "react";
import { lazy, Suspense } from "react";
import "./App.css";

import Dashboard from "./pages/Dashboard/Dashboard";
import {
  Outlet,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import io from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import ChallengeDetails from "./components/Tables/Organisation/ChallengeDetails";

const Login = lazy(() => import("../src/pages/Auth/Login"));
const UserManagement = lazy(() =>
  import("../src/pages/UserManagement/UserManagement")
);
const WorkoutManagement = lazy(() =>
  import("../src/pages/WorkoutManagement/WorkoutTabs")
);
const Eventmanagement = lazy(() =>
  import("../src/pages/EventManagement/EventTabs")
);
const NutritionManagement = lazy(() =>
  import("../src/pages/NutritionManagement/NurtitionTabs")
);
const CommnityChallengeManagement = lazy(() =>
  import("../src/pages/CommunityChallengeManagement/CommunityChallengeTabs")
);
const ViewEventManagement = lazy(() =>
  import("../src/components/Tables/EventManagement/ViewEventNew")
);
const ViewUserEventManagement = lazy(() =>
  import("../src/components/Tables/EventManagement/ViewUserEventManagement")
);
const ViewCommunityManagement = lazy(() =>
  import(
    "../src/components/Tables/CommunityChallengeManagement/ViewCommunityManagement"
  )
);
const ViewWorkout = lazy(() =>
  import("../src/components/Tables/WorkoutManagement/ViewWorkoutManagement")
);
const ViewNutritionManagement = lazy(() =>
  import("../src/components/Tables/NutritionManagement/ViewNutritionManagement")
);
const AddNutrition = lazy(() =>
  import("../src/components/Tables/NutritionManagement/AddNutrition")
);
const EditNutrition = lazy(() =>
  import("../src/components/Tables/NutritionManagement/EditNutrition")
);
const MentalHealthManagement = lazy(() =>
  import("../src/pages/MentalHealthManagement/MentalHealthTabs")
);
const ViewMentalHealth = lazy(() =>
  import("../src/components/Tables/MentalHealthManagement/ViewMentalHealth")
);
const AddMentalHealth = lazy(() =>
  import("../src/components/Tables/MentalHealthManagement/AddMentalHealth")
);
const EditMentalHealth = lazy(() =>
  import("../src/components/Tables/MentalHealthManagement/EditMentalHealth")
);
const CmsManagement = lazy(() => import("../src/pages/Cms/Cms"));
const EditAbout = lazy(() => import("../src/components/Tables/Cms/EditAbout"));
const EditPrivacy = lazy(() =>
  import("../src/components/Tables/Cms/EditPrivacy")
);
const EditTerms = lazy(() => import("../src/components/Tables/Cms/EditTerms"));
const AddEvent = lazy(() =>
  import("../src/components/Tables/EventManagement/AddEvent")
);
const EditEvent = lazy(() =>
  import("../src/components/Tables/EventManagement/EditEvent")
);
const Feedbacks = lazy(() => import("../src/pages/Feedbacks/Feedbacks"));
const UserRegisteredEvent = lazy(() =>
  import("../src/pages/EventManagement/UserRegisteredEvent")
);
const Vendormanagement = lazy(() =>
  import("../src/pages/Vendor/VendorManagement")
);
const AddVendor = lazy(() =>
  import("../src/components/Tables/Vendor/AddNutrition")
);
const AddWorkOut = lazy(() =>
  import("../src/components/Tables/WorkoutManagement/AddWorkout")
);
const EditWorkOut = lazy(() =>
  import("../src/components/Tables/WorkoutManagement/EditWorkout")
);
const AddCommunity = lazy(() =>
  import("../src/components/Tables/CommunityChallengeManagement/AddCommunity")
);
const EditCommunity = lazy(() =>
  import("../src/components/Tables/CommunityChallengeManagement/EditCommunity")
);
const Tips = lazy(() => import("../src/pages/Tip/TipsNew"));
const EditTips = lazy(() => import("./components/Tables/Tips/EditTips"));
const AddTips = lazy(() => import("./components/Tables/Tips/AddTips"));
const ViewTips = lazy(() => import("./components/Tables/Tips/ViewTips"));
const ViewProfileTips = lazy(() =>
  import("./components/Tables/Tips/ViewProfileTips")
);
const EditProfileTips = lazy(() =>
  import("./components/Tables/Tips/EditProfileTips")
);
const Ramadan = lazy(() => import("../src/pages/Ramadan/RamadanTabs"));
const ViewRamadan = lazy(() =>
  import("../src/components/Tables/RamadanManagement/ViewRamadan")
);
const AddRamadan = lazy(() =>
  import("../src/components/Tables/RamadanManagement/AddRamadan")
);
const EditRamadan = lazy(() =>
  import("../src/components/Tables/RamadanManagement/EditRamadan")
);

const AddHomeTip = lazy(() =>
  import("../src/components/Tables/Tips/AddHomeTip")
);
const AddNutritionTip = lazy(() =>
  import("../src/components/Tables/Tips/AddNutritionTip")
);
const AddFitnessTip = lazy(() =>
  import("../src/components/Tables/Tips/AddFitnessTip")
);
const AddMoodTip = lazy(() =>
  import("../src/components/Tables/Tips/AddMoodTip")
);
const EditHomeTip = lazy(() => import("./components/Tables/Tips/EditHomeTip"));
const EditNutritionTip = lazy(() =>
  import("./components/Tables/Tips/AddNutritionTip")
);
const EditFitnessTip = lazy(() =>
  import("./components/Tables/Tips/EditFitnessTip")
);
const EditMoodTip = lazy(() => import("./components/Tables/Tips/EditMoodTip"));
const PartnerImages = lazy(() =>
  import("../src/pages/PartnerImages/PartnerImages")
);
const Reports = lazy(() => import("../src/pages/Reports/Reports"));
const AddPartnerImage = lazy(() =>
  import("./components/Tables/PartnerImages/AddImage")
);
const RecommendedVideos = lazy(() =>
  import("../src/pages/RecommendedVideos/RecommendedVideos")
);
const AddVideos = lazy(() =>
  import("./components/Tables/RecommendedVideos/AddVideos")
);
const EditVideos = lazy(() =>
  import("./components/Tables/RecommendedVideos/EditVideos")
);
const ViewVideo = lazy(() =>
  import("./components/Tables/RecommendedVideos/ViewVideo")
);
const RewardsManagement = lazy(() =>
  import("./pages/RewardsManagement/RewardsTab")
);
const FitnessArticles = lazy(() =>
  import("./pages/FitnessArticles/FitnessTab")
);
const UserHistory = lazy(() =>
  import("./components/Tables/UserManagement/PointsHistory")
);

const ViewFitnessManagement = lazy(() =>
  import("../src/components/Tables/FitnessManagement/ViewNutritionManagement")
);
const AddFitness = lazy(() =>
  import("../src/components/Tables/FitnessManagement/AddNutrition")
);
const EditFitness = lazy(() =>
  import("../src/components/Tables/FitnessManagement/EditNutrition")
);
const RolesManagement = lazy(() =>
  import("../src/pages/RolesManagement/RolesManagement")
);
const ViewRolesManagement = lazy(() =>
  import("../src/components/RolesManagement/ViewRolesManagement ")
);
const AddSubAdmin = lazy(() =>
  import("../src/components/RolesManagement/AddSubAdmin")
);
const ChangePassword = lazy(() =>
  import("../src/pages/ChangePassword/ChangePassword")
);
const DuplicateEvent = lazy(() =>
  import("../src/components/Tables/EventManagement/DuplicateEvent")
);
const Faq = lazy(() => import("./pages/Faq/FaqTabs"));
const EditFaq = lazy(() => import("../src/components/Tables/Faq/EditFaq"));
const AddFaq = lazy(() => import("../src/components/Tables/Faq/AddFaq"));
const AddGeneral = lazy(() =>
  import("../src/components/Tables/Faq/AddGeneral")
);
const AddReward = lazy(() => import("../src/components/Tables/Faq/AddReward"));
const EditGeneral = lazy(() =>
  import("../src/components/Tables/Faq/EditGeneral")
);
const EditReward = lazy(() =>
  import("../src/components/Tables/Faq/EditReward")
);

const Badges = lazy(() => import("../src/pages/Badges/Badges"));
const Widgets = lazy(() => import("../src/pages/Widgets/Widgets"));
const AddWidget = lazy(() => import("../src/pages/Widgets/AddWidget"));
const EditWidget = lazy(() => import("../src/pages/Widgets/EditWidget"));
const ViewWidget = lazy(() => import("../src/pages/Widgets/ViewWidget"));
const AddBadge = lazy(() => import("../src/components/Tables/Badges/AddBadge"));
const ViewBadge = lazy(() =>
  import("../src/components/Tables/Badges/ViewBadge")
);
const EditBadge = lazy(() =>
  import("../src/components/Tables/Badges/EditBadge")
);
const Organization = lazy(() =>
  import("../src/pages/Organization/Organisaton")
);
const License = lazy(() =>
  import("../src/components/Tables/Organisation/LicenseApproval")
);
const AccountInformation = lazy(() =>
  import("../src/components/Tables/Organisation/AccountInformation")
);
const EmployeesAssociated = lazy(() =>
  import("../src/components/Tables/Organisation/EmployeesAssociated")
);
const ChallengesOrganisation = lazy(() =>
  import("../src/components/Tables/Organisation/Challenges")
);
const DashboardOrganisation = lazy(() =>
  import("../src/components/Tables/Organisation/Dashboard")
);
const EmployeeDetails = lazy(() =>
  import("../src/components/Tables/Organisation/EmployeeDetails")
);
const OrganisationCMS = lazy(() => import("../src/pages/OrganisationCMS/Cms"));
const EditAboutOrg = lazy(() =>
  import("../src/components/Tables/OrganisationCMS/EditAbout")
);
const EditPrivacyOrg = lazy(() =>
  import("../src/components/Tables/OrganisationCMS/EditPrivacy")
);
const EditTermsOrg = lazy(() =>
  import("../src/components/Tables/OrganisationCMS/EditTerms")
);
const AddOrgFaq = lazy(() =>
  import("../src/components/Tables/OrganisationCMS/AddOrgfaq")
);
const EditOrgFaq = lazy(() =>
  import("../src/components/Tables/OrganisationCMS/EditOrgFaq")
);

function App() {
  const PrivateOutlet = () => {
    const statusCode = localStorage.getItem("status") || "";
    const loginSuccess = useSelector(
      (state) => state.loginInfo.userInformation
    );
    const loginStatus =
      (loginSuccess && loginSuccess.statusCode) ||
      (statusCode && JSON.parse(statusCode) === 200) ||
      false;

    return loginStatus ? <Outlet /> : <Login />;
  };

  function isSessionExpired() {
    const lastActivityTime = localStorage.getItem("lastActivityTime");
    if (lastActivityTime) {
      return Date.now() - lastActivityTime > 60 * 60 * 1000; // 1 hour in milliseconds
      // return Date.now() - lastActivityTime > 1000; // 1 hour in milliseconds
    }
    return true;
  }
  useEffect(() => {
    if (isSessionExpired()) {
      localStorage.clear();
    }
  }, []);

  function handleActivity() {
    localStorage.setItem("lastActivityTime", Date.now());
  }

  useEffect(() => {
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("touchstart", handleActivity);
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
    };
  }, []);
  useEffect(() => {
   
    const socket = io('https://backend.abudhabi360.ae/');

    socket.on('connect', () => {
      console.log('Socket.IO connection established');
     
    });

    socket.on('reports', (message) => {
      console.log('Socket.IO message received:', message);
      if(message?.status=='success'){
      toast.success(message?.fileName+" "+"is"+" "+message?.status, {
        className: "custom-toast-body success",
        bodyClassName: "custom-toast-container",
        iconClassName: "Toastify__toast-icon success",
      })
    }else{
      toast.error(message?.fileName+" "+"is"+" "+message?.status, {
        className: "custom-toast-body error",
        bodyClassName: "custom-toast-container",
        iconClassName: "Toastify__toast-icon error",
      
    })
}
    })
    socket.on('connect_error', (err) => {
      console.error('Socket.IO connection error:', err);
     
    });

    socket.on('disconnect', () => {
      console.log('Socket.IO connection closed');
 
    });

    // Cleanup on component unmount
    // return () => {
    //   socket.close();
    // };
  }, []);
  // useEffect(() => {
  //   // Add the event listener for beforeunload
  //   window.addEventListener("beforeunload", localStorage.clear());

  //   // Remove the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("beforeunload", localStorage.clear());
  //   };
  // }, []);
  return (
    <>
      <ToastContainer
        position={ "top-right"}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Router>
        <Suspense fallback="Loading....">
          <Routes>
            <Route exact path="" element={<Login />} />
            <Route element={<PrivateOutlet />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-management" element={<UserManagement />} />
              <Route
                path="/workout-management"
                element={<WorkoutManagement />}
              />
              <Route path="/event-management" element={<Eventmanagement />} />
              <Route
                path="/user-registered-event"
                element={<UserRegisteredEvent />}
              />
              <Route path="/vendor-management" element={<Vendormanagement />} />
              <Route path="/add-vendor" element={<AddVendor />} />
              <Route
                path="/nutrition-management"
                element={<NutritionManagement />}
              />
              <Route
                path="/community-challenge-management"
                element={<CommnityChallengeManagement />}
              />
              <Route
                path="/view-event-management/:id"
                element={<ViewEventManagement />}
              />
              <Route
                path="/view-user-event-management/:id"
                element={<ViewUserEventManagement />}
              />
              <Route
                path="/view-community-management/:id"
                element={<ViewCommunityManagement />}
              />
              <Route
                path="/view-workout-management/:id"
                element={<ViewWorkout />}
              />
              <Route
                path="/view-nutrition-management/:id"
                element={<ViewNutritionManagement />}
              />
              <Route path="/add-nutrition" element={<AddNutrition />} />
              <Route path="/edit-nutrition/:id" element={<EditNutrition />} />
              <Route
                path="/mental-health-management"
                element={<MentalHealthManagement />}
              />
              <Route path="/add-mental-health" element={<AddMentalHealth />} />
              <Route
                path="/edit-mental-health/:id"
                element={<EditMentalHealth />}
              />
              <Route
                path="/view-mental-health/:id"
                element={<ViewMentalHealth />}
              />
              <Route path="/cms-management" element={<CmsManagement />} />
              <Route path="/edit-aboutus" element={<EditAbout />} />
              <Route path="/edit-privacy" element={<EditPrivacy />} />
              <Route path="/edit-terms-and-conditons" element={<EditTerms />} />
              <Route path="/add-event" element={<AddEvent />} />
              <Route path="/edit-event/:id" element={<EditEvent />} />
              <Route path="/feedbacks" element={<Feedbacks />} />
              <Route path="/add-workout" element={<AddWorkOut />} />
              <Route path="/edit-workout/:id" element={<EditWorkOut />} />
              <Route path="/add-community" element={<AddCommunity />} />
              <Route path="/edit-community/:id" element={<EditCommunity />} />
              <Route path="/tips" element={<Tips />} />
              <Route path="/edit-tip/:id" element={<EditTips />} />
              <Route path="/add-tips/:id" element={<AddTips />} />
              <Route path="/view-tip/:id" element={<ViewTips />} />
              <Route
                path="/view-profile-tips/:id"
                element={<ViewProfileTips />}
              />
              <Route
                path="/edit-profile-tips/:id"
                element={<EditProfileTips />}
              />
              <Route path="/ramadan" element={<Ramadan />} />
              <Route path="/add-ramadan" element={<AddRamadan />} />
              <Route path="/edit-ramadan/:id" element={<EditRamadan />} />
              <Route path="/view-ramadan/:id" element={<ViewRamadan />} />
              <Route path="/add-home-tip" element={<AddHomeTip />} />
              <Route path="/add-nutrition-tip" element={<AddNutritionTip />} />
              <Route path="/add-fitness-tip" element={<AddFitnessTip />} />
              <Route path="/add-mood-tip" element={<AddMoodTip />} />
              <Route path="/edit-home-tip/:id" element={<EditHomeTip />} />
              <Route
                path="/edit-nutrition-tip/:id"
                element={<EditNutritionTip />}
              />
              <Route
                path="/edit-fitness-tip/:id"
                element={<EditFitnessTip />}
              />
              <Route path="/edit-mood-tip/:id" element={<EditMoodTip />} />
              <Route path="/partner-images" element={<PartnerImages />} />
              <Route path="/add-partner-image/" element={<AddPartnerImage />} />
              <Route
                path="/recommended-videos/"
                element={<RecommendedVideos />}
              />
              <Route path="/add-videos/" element={<AddVideos />} />
              <Route path="/edit-videos/:id" element={<EditVideos />} />
              <Route path="/view-Video/:id" element={<ViewVideo />} />
              <Route path="/user-history/:id" element={<UserHistory />} />
              <Route
                path="/rewards-management/"
                element={<RewardsManagement />}
              />
              <Route path="/fitness-articles/" element={<FitnessArticles />} />
              <Route
                path="/view-fitness-management/:id"
                element={<ViewFitnessManagement />}
              />
              <Route path="/add-fitness" element={<AddFitness />} />
              <Route path="/edit-fitness/:id" element={<EditFitness />} />
              <Route path="/roles-management" element={<RolesManagement />} />
              <Route
                path="/view-roles-management/:id"
                element={<ViewRolesManagement />}
              />
              <Route path="/add-subadmin" element={<AddSubAdmin />} />
              <Route path="/change-password/:id" element={<ChangePassword />} />
              <Route path="/change-password/:id" element={<ChangePassword />} />
              <Route path="/duplicate-event/:id" element={<DuplicateEvent />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/edit-reward-faq/:id" element={<EditReward />} />
              <Route path="/edit-general-faq/:id" element={<EditGeneral />} />

              <Route path="/add-faq" element={<AddFaq />} />
              <Route path="/add-general" element={<AddGeneral />} />
              <Route path="/add-reward" element={<AddReward />} />
              <Route path="/reports-management" element={<Reports />} />
              <Route path="/badges" element={<Badges />} />
              <Route path="/widgets" element={<Widgets />} />
              <Route path="/add-widget" element={<AddWidget />} />
              <Route path="/edit-widget/:id" element={<EditWidget />} />
              <Route path="/view-widget/:id" element={<ViewWidget />} />
              <Route path="/add-badge" element={<AddBadge />} />
              <Route path="/view-badge/:id" element={<ViewBadge />} />
              <Route path="/edit-badge/:id" element={<EditBadge />} />
              <Route path="/license-approval" element={<License />} />
              {/* <Route path="/license-approval" element={<License />} /> */}
              <Route
                path="/account-information"
                element={<AccountInformation />}
              />
              <Route
                path="/employees-associated"
                element={<EmployeesAssociated />}
              />
              <Route
                path="/organization-challenges"
                element={<ChallengesOrganisation />}
              />
              <Route
                path="/organisation-dashboard"
                element={<DashboardOrganisation />}
              />
              <Route
                path="/employee-details/:id"
                element={<EmployeeDetails />}
              />
              <Route
                path="/challenge-details/:id"
                element={<ChallengeDetails />}
              />
              <Route path="/organisation-cms" element={<OrganisationCMS />} />
              <Route path="/edit-aboutus-org" element={<EditAboutOrg />} />
              <Route path="/edit-privacy-org" element={<EditPrivacyOrg />} />
              <Route
                path="/edit-terms-and-conditons-org"
                element={<EditTermsOrg />}
              />
              <Route path="/add-organisation-faq" element={<AddOrgFaq />} />
              <Route
                path="/edit-organisation-faq/:id"
                element={<EditOrgFaq />}
              />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
