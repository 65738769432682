import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: "eventInformation",
  initialState: {
    eventInformation: {},
  },
  reducers: {
    setEventData: (state, action) => {
      state.eventInformation = action.payload;
    },
  },
});

export const { setEventData } = eventSlice.actions;

export default eventSlice.reducer;
