import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
  name: "pageInformation",
  initialState: {
    pageInformation: {},
  },
  reducers: {
    setPageData: (state, action) => {
      state.pageInformation = action.payload;
    },
  },
});

export const { setPageData } = pageSlice.actions;

export default pageSlice.reducer;
