import React, { useEffect, useRef } from "react";
import styles from "./Modal.module.css";

const Modal = ({
  modalStyle,
  children,
  show,
  onClose,
  backdropStyle,
  smModal,
}) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);
  return (
    <React.Fragment>
      <div
        ref={modalRef}
        style={{ overflow: "scroll" }}
        className={`${styles.modal__wrap} ${smModal}`}
        onClick={onClose}
      >
        <div
          style={{ modalStyle, overflow: "visible" }}
          className={`${styles.modal} flex flex-col rounded-lg p-0`}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={onClose}
            className="ml-auto py-0 px-2"
            style={{ border: "none", background: "#e5e5e5" }}
          >
            {/* <i
              class="fa-solid fa-xmark bold"
              style={{ color: "#444", fontSize: "14px" }}
            ></i> */}
          </button>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
