import React from 'react';
import Pagination from 'react-bootstrap/Pagination'; // Assuming you're using Bootstrap Pagination component

const renderPaginationItems = ({ page, pages, handlePaginationClick }) => {
  const items = [];
  const maxVisiblePages = 5; // Adjust the number of visible pages as needed

  // Ellipsis component
  const ellipsis = <Pagination.Ellipsis key="ellipsis" />;

  // Add First and Prev buttons
  items.push(
    <Pagination.First key="first" onClick={() => handlePaginationClick(1)} />,
    <Pagination.Prev
      key="prev"
      onClick={() => handlePaginationClick(Math.max(page - 1, 1))}
    />
  );

  // Add ellipsis if there are more than maxVisiblePages pages
  if (pages > maxVisiblePages) {
    items.push(ellipsis);
  }

  // Calculate the range of visible page numbers
  const start = Math.max(1, page - Math.floor(maxVisiblePages / 2));
  const end = Math.min(pages, start + maxVisiblePages - 1);

  // Render the visible page numbers
  for (let i = start; i <= end; i++) {
    items.push(
      <Pagination.Item
        key={i}
        active={i === page}
        onClick={() => handlePaginationClick(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  // Add ellipsis if there are more than maxVisiblePages pages
  if (end < pages) {
    items.push(ellipsis);
  }

  // Add Next and Last buttons
  items.push(
    <Pagination.Next
      key="next"
      onClick={() => handlePaginationClick(Math.min(page + 1, pages))}
    />,
    <Pagination.Last
      key="last"
      onClick={() => handlePaginationClick(pages)}
    />
  );

  return items;
};

export default renderPaginationItems;
