import React, { useState, useEffect } from "react";
import Header from "../../../commons/Header/Header";
import Sidebar from "../../../commons/Sidebar/Sidebar";
import viewImage from "../../../assets/images/icons/Show1.png";
import deleteImage from "../../../assets/images/icons//Delete1.png";
import editImage from "../../../assets/images/icons/edit.svg";
import ARROW_LEFT from "../../../assets/images/icons/Arrow - Left 1.png";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { handleApiCall } from "../../../apis/index";
import { endpoints } from "../../../utils/endpoints";
import { PopUp } from "../../../utils/utilities";
import Pagination from "react-bootstrap/Pagination";
import renderPaginationItems from "../../../commons/Pagination/renderPaginationItems";
import moment from "moment";
import Modal from "../../../commons/Modal/Modal";
import axios from 'axios';
import { useLocation } from 'react-router-dom';


const ChallengeDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [employeeList, setEmlployeeList] = useState();
  const [pages, setPages] = useState();
  const [page, setPage] = useState(1);
  const [view, setView] = useState({ value: false, id: "" });
  const [employeeDetail, setEmployeeDetail] = useState();
  const apiUrl = `${process.env.REACT_APP_API}${endpoints.deleteChallenge}`;
  const location = useLocation();
  const { companyName } = location.state || {};
  const [isDelete, setIsDelete] = useState({
    value: false,
    id: "",
    type: "",
  });

  const handlePaginationClick = (selectedPage) => {
    setPage(selectedPage ? selectedPage : page);
  };

  async function handleEmployeeList() {
    try {
      const response = await handleApiCall(
        "get",
        `${endpoints.organisationChallenge}${params?.id}`
      );
      if (response.data.status == true) {
        setEmlployeeList(response?.data?.data);
        setPages(response?.data?.data?.totalPages);
        
        
      } else {
        PopUp(`Something went wrong`, "", "error");
      }
    } catch (error) {
      PopUp(`Something went wrong`, "", "error");
    }
  }

  //   async function handleEmployeeDetails() {
  //     try {
  //       const response = await handleApiCall(
  //         "post",
  //         `${endpoints.organisationChallenge}`,
  //         {
  //           organizationId: params?.id,
  //           employeeId: view?.id,
  //         }
  //       );
  //       if (response.data.status == true) {
  //         setEmployeeDetail(response?.data?.data);
  //         setPages(response?.data?.data?.totalPages);
  //       } else {
  //         PopUp(`Something went wrong`, "", "error");
  //       }
  //     } catch (error) {
  //       PopUp(`Something went wrong`, "", "error");
  //     }
  //   }

  async function handleDelete() {
    try {
      const baseUrl = process.env.REACT_APP_API;
      const endpoint = endpoints.deleteChallenge;
      const url = `${baseUrl}/${endpoint}`;
      const data = {
        communityId: isDelete?.id
      };
  
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
  
      if (responseData.status === true) {
        PopUp("Challenge deleted successfully", "", "success");
        setIsDelete({ value: false });
        handleEmployeeList();
      }
    } catch (error) {
      console.error("Something went wrong", error);
    }
  }
  

 

  useEffect(() => {
    // if (view?.id) {
    //   handleEmployeeDetails();
    // }
    handleEmployeeList();
  }, [page, view]);

  console.log("params", view);
  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidebar status={true} />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="heading mb-4">
              <h3 className="text-default weight-400">
                Organisation/{companyName}
              </h3>
              <a
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => navigate(-1)}
              >
                <img src={ARROW_LEFT} /> Back
              </a>
            </div>
            <div className="card mb-4 px-4 py-4">
              <div className="">
                <div className="flex-fill d-flex align-items-center">
                  <div className="table-responsive">
                    <table className="table table-striped trim">
                      <thead>
                        <tr>
                          <th>S.no.</th>
                          <th>Organisation</th>
                          <th>Challenge name</th>
                          {/* <th>Start date</th> */}
                          <th>Start date</th>
                          <th>End date</th>
                          <th>Activity</th>
                          <th>Goal</th>
                          <th>Limit</th>
                          <th>Enrolled employees</th>

                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeList?.challenges?.length > 0 &&
                          employeeList?.challenges?.map((data, index) => {
                            return (
                              <>
                                <tr>
                                  <td> {page * 15 + index - 14}</td>

                                  <td className="text-center">{companyName}</td>
                                  <td>{data?.title}</td>
                                  <td>
                                    {" "}
                                    {moment(data?.start_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(data?.end_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>{data?.type}</td>
                                  <td>{data?.distance ? "Distance" : "Steps"}</td>

                                  <td>{data?.distance ? data?.distance : data?.steps !=="" ? `${data?.steps} steps` :""}</td>
                                  {/* <td>{data?.Complted_challenge}</td> */}

                                  <td>{data?.enrolled_employees}</td>
                                  <td className="text-center">
                                    <div className="action text-center">
                                      <a
                                        onClick={() =>
                                          setView({
                                            value: true,
                                            id: data?._id,
                                            title: data?.title,
                                            description: data?.description,
                                            startData: data?.start_date,
                                            endDate: data?.end_date,
                                            activity: data?.type,
                                            distance: data?.distance,
                                            steps:data?.steps
                                          })
                                        }
                                      >
                                        <img src={viewImage} />
                                      </a>

                                      {/* <a
                                        onClick={() =>
                                          setIsDelete({
                                            value: true,
                                            id: data?._id,
                                          })
                                        }
                                      >
                                        <img src={deleteImage} />
                                      </a> */}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                
                  </div>
                </div>
                {employeeList?.challenges?.length > 0 ? (
                <div className="mt-5 d-flex justify-content-end">
                      <Pagination>
                        {renderPaginationItems({
                          page,
                          pages,
                          handlePaginationClick,
                        })}
                      </Pagination>
                    </div>
                        ) : (
                          ""
                        )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={view.value}
        onClose={() => setView({ value: false })}
        style={{ zIndex: "1000" }}
      >
        <div>
          <div class="modal-dialog modal-md modal-center" role="document">
            <div class="modal-content">
              <div class="modal-body my-4 py-4">
                <h3 class="text-dark text-center">View Challenge</h3>
                <div style={{ marginLeft: "2rem" }}>
                  <div>
                    <h5 className="w-50">Organisation:</h5>
                    <p className="w-50">{companyName}</p>
                  </div>
                  <div>
                    <h5 className="w-50">Challenge name:</h5>
                    <p>{view?.title}</p>
                  </div>
                  <div>
                    <h5 className="w-50">Description:</h5>
                    <p>{view?.description}</p>
                  </div>
                  <div
                    className="w-75"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "5%",
                    }}
                  >
                    <h5 className="w-50" style={{ textAlign: "left" }}>
                      Start date:
                    </h5>
                    <span className="w-50" style={{ textAlign: "left" }}>
                      {moment(view?.startDate).format("DD-MM-YYYY")}
                    </span>
                  </div>
                  <div
                    className="w-75"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "5%",
                    }}
                  >
                    <h5 className="w-50" style={{ textAlign: "left" }}>
                      End date:
                    </h5>
                    <span className="w-50" style={{ textAlign: "left" }}>
                      {moment(view?.endDate).format("DD-MM-YYYY")}
                    </span>
                  </div>
                  <div
                    className="w-75"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "5%",
                    }}
                  >
                    <h5 className="w-50" style={{ textAlign: "left" }}>
                      Activity
                    </h5>
                    <span className="w-50" style={{ textAlign: "left" }}>
                      {view?.activity}
                    </span>
                  </div>
                  <div
                    className="w-75"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "5%",
                    }}
                  >
                    <h5 className="w-50" style={{ textAlign: "left" }}>
                      Limit:
                    </h5>
                    <span className="w-50" style={{ textAlign: "left" }}>
                    <td>{view?.distance ? view?.distance : view?.steps !=="" ? `${view?.steps} steps` :""}</td>
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 mx-auto d-flex align-items-center mt-4 row">
                  <div class="col-6 px-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-lg btn-block font-16"
                      data-dismiss="modal"
                      onClick={() => setView({ value: false })}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={isDelete.value}
        onClose={() => setIsDelete({ value: false })}
        style={{ zIndex: "1000" }}
      >
        <div>
          <div class="modal-dialog modal-md modal-center" role="document">
            <div class="modal-content">
              <div class="modal-body text-center my-4 py-4">
                <h4 class="text-dark">
                  Are you sure you want to
                  <br />
                  delete this organisation?
                </h4>
                <div class="col-sm-12 mx-auto d-flex align-items-center mt-4 row">
                  <div class="col-6 px-2">
                    <a
                      onClick={()=>handleDelete()}
                      type="button"
                      class="btn btn-primary	btn-lg btn-block font-16"
                    >
                      Yes
                    </a>
                  </div>
                  <div class="col-6 px-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-lg btn-block font-16"
                      data-dismiss="modal"
                      onClick={() => setIsDelete({ value: false })}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChallengeDetails;
