import React, { useState, useEffect } from "react";
import Sidebar from "../../commons/Sidebar/Sidebar";
import Header from "../../commons/Header/Header";
import userImage from "../../assets/images/icons/user.svg";
import userPlusImage from "../../assets/images/icons/userplus.svg";
import { handleApiCall } from "../../apis";
import { endpoints } from "../../utils/endpoints";

function Dashboard() {
  const [dashboard, setDashboard] = useState();

  useEffect(() => {
    handleDashboard();
  }, []);

  const handleDashboard = async () => {
    try {
      const response = await handleApiCall(
        "get",
        `${endpoints.dashboardDetails}`
      );
      if (response.data.status == true) {
        setDashboard(response.data.data);
      }
    } catch (error) {
      console.log("something went wrong", error);
    }
  };


  return (
    <>
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="heading mb-4">
                <h3 className="text-default weight-400">Dashboard</h3>
              </div>
              <div className="row">
                <div className="col-md-4 stretch-card grid-margin">
                  <div className="card card-img-holder text-default">
                    <div className="card-body d-flex align-items-center justify-content-between">
                      <div className="left my-2">
                        <h2 className="mb-2 text-black">{dashboard?.activeUsers}</h2>
                        <h5 className="font-weight-normal mb-0">Active Users</h5>
                      </div>
                      
                      <div className="right my-2">
                        <img src={userImage} width="70px" className="icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 stretch-card grid-margin">
                  <div className="card card-img-holder text-default">
                    <div className="card-body d-flex align-items-center justify-content-between">
                      <div className="left my-2">
                        <h2 className="mb-2 text-black">{dashboard?.inactiveUsers}</h2>
                        <h5 className="font-weight-normal mb-0">Inactive Users</h5>
                      </div>
                      
                      <div className="right my-2">
                        <img src={userImage} width="70px" className="icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 stretch-card grid-margin">
                  <div className="card card-img-holder text-default">
                    <div className="card-body d-flex align-items-center justify-content-between">
                      <div className="left my-2">
                        <h2 className="mb-2 text-black">{dashboard?.rewardsRedeemed ? dashboard?.rewardsRedeemed : "Not available" }</h2>
                        <h5 className="font-weight-normal mb-0">
                          Rewards Redeemed
                        </h5>
                      </div>
                      <div className="right my-2">
                        <img
                          src={userPlusImage}
                          width="60px"
                          className="icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
